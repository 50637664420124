import BasePlugin from '../BasePlugin'

export default class RejectAdjustmentRequestCommand extends BasePlugin {
  async execute () {
    let me = this
    let me2 = this
    let adjustmentRequestData = {
      'adjustmentRequestId': this.context.getCard().data.id,
      'actionTypeId': 1
    }
    this.context.getCard().setLoading(true)

    this.context.$msgbox({
      type: 'info',
      confirmButtonText: 'Да',
      showCancelButton: true,
      message: 'Вы действительно хотите отклонить процедуру согласования?',
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/CheckAdjustmentRequestRuleCommand`,
            adjustmentRequestData,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          ).then((response) => {
            if (response.data.rule_id) {
              this.context.getCard().setLoading(false)
              if (response.data.is_require_additional_confirmation_from_user) {
                this.context.$msgbox({
                  type: 'info',
                  confirmButtonText: 'Да',
                  showCancelButton: true,
                  center: true,
                  message: response.data.message,
                  beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                      this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddApprovalHistoryRecordBeforeAdjustmentRequestRejectCommand`,
                        { 'adjustmentRequestId': this.context.getCard().data.id },
                        {
                          headers: {
                            'Content-Type': 'application/json'
                          }
                        }
                      )
                      this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetAdjustmentRequestRuleCommand`,
                        adjustmentRequestData,
                        {
                          headers: {
                            'Content-Type': 'application/json'
                          }
                        }
                      ).then((response) => {
                        if (response.data.is_base_scenario) {
                          this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetInvestmentRequestBaseScenarioAsyncCommand`,
                            {
                              'scenarioId': response.data.scenario_id,
                              'async': 'true'
                            },
                            {
                              headers: {
                                'Content-Type': 'application/json'
                              }
                            })
                        }
                      })
                      done()
                    } else {
                      done()
                    }
                    this.context.$message({
                      type: 'success',
                      message: 'Процедура согласования отклонена'
                    })
                    this.context.getCard().$emit('cancelChanges')
                  }
                })
              } else {
                this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddApprovalHistoryRecordBeforeAdjustmentRequestRejectCommand`,
                  { 'adjustmentRequestId': this.context.getCard().data.id },
                  {
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }
                )
                this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetAdjustmentRequestRuleCommand`,
                  adjustmentRequestData,
                  {
                    headers: {
                      'Content-Type': 'application/json'
                    }
                  }
                ).then((response) => {
                  if (response.data.message) {
                    me.context.$msgbox({
                      type: 'info',
                      message: response.data.message
                    })
                  }
                  if (response.data.is_base_scenario) {
                    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/SetInvestmentRequestBaseScenarioAsyncCommand`,
                      {
                        'scenarioId': response.data.scenario_id,
                        'async': 'true'
                      },
                      {
                        headers: {
                          'Content-Type': 'application/json'
                        }
                      })
                  }
                })
                this.context.$message({
                  type: 'success',
                  message: 'Процедура согласования отклонена'
                })
                this.context.getCard().$emit('cancelChanges')
              }
            } else {
              me.context.$msgbox({
                type: 'info',
                message: 'Действие не было выполнено. Информация в корректировке не соответствует ни одному из существующих правил репликации статусов целевого сценария'
              })
            }
          })
          this.context.getCard().setLoading(false)
          done()
        } else {
          this.context.getCard().setLoading(false)
          done()
        }
      }
    })
  }
}
